<template>
  <canvas :id="id" height="300"></canvas>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    chartRange: {
      required: true,
      type: Object,
    },
    titleLeft: {
      required: false,
      type: String,
    },
    titleBottom: {
      required: false,
      type: String,
    },
    max: {
      required: false,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);

      var data = {
        labels: this.chartRange.label,
        dataPercent: this.chartRange.$percent,
        datasets: [
          {
            label: "Min (1)",
            type: "bar",
            fill: false,
            order: 1,
            backgroundColor: "#E91C2B",
            data: this.chartRange.count.min.map((el) => el.percent || 0),
            num: this.chartRange.count.min.map((el) => el.num_data || 0),
          },
          {
            label: "Average Min (2)",
            type: "bar",
            fill: false,
            order: 1,
            backgroundColor: "#F48A1D",
            data: this.chartRange.count.avg_min.map((el) => el.percent || 0),
            num: this.chartRange.count.avg_min.map((el) => el.num_data || 0),
          },
          {
            label: "Average (3)",
            type: "bar",
            order: 1,
            fill: false,
            backgroundColor: "#EACF2A",
            data: this.chartRange.count.avg.map((el) => el.percent || 0),
            num: this.chartRange.count.avg.map((el) => el.num_data || 0),
          },
          {
            label: "Average Max (4)",
            type: "bar",
            fill: false,
            order: 1,
            backgroundColor: "#0BA350",
            data: this.chartRange.count.avg_max.map((el) => el.percent || 0),
            num: this.chartRange.count.avg_max.map((el) => el.num_data || 0),
          },
          {
            label: "Max (5)",
            type: "bar",
            fill: false,
            order: 1,
            backgroundColor: "#3483B1",
            data: this.chartRange.count.max.map((el) => el.percent || 0),
            num: this.chartRange.count.max.map((el) => el.num_data || 0),
          },
          {
            label: "Accmulate",
            type: "line",
            fill: false,
            order: 0,
            backgroundColor:
              this.$store.state.theme.themeLogo.result.themePrimaryColor,
            borderColor:
              this.$store.state.theme.themeLogo.result.themePrimaryColor,
            data: this.chartRange.accumulate.percent.map((el) => el || 0),
            num: this.chartRange.accumulate.num.map((el) => el || 0),
          },
        ],
      };
      var that = this;
      var myChart = new this.$Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          plugins: {
            datalabels: {
              display:
                this.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? true
                  : false,
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, index, values) {
                return value == 0 ? "" : value + "%";
              },
            },
          },

          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `(${data.labels[tooltipItem[0].index]})`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Total";

                let numData =
                  data.datasets[tooltipItem.datasetIndex].num[
                    tooltipItem.index
                  ];
                return `${datasetLabel}: ${numData} (${that.addCommas(
                  tooltipItem.value
                )}%)`;
              },
              footer: function (items, data) {
                return "Total: " + data.dataPercent[items[0].index] + "%";
              },
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: this.titleBottom,
                  fontStyle: "bold",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  fontSize: 10,
                },
              },
            ],
            yAxes: [
              {
                type: "linear",
                stacked: true,
                ticks: {
                  stepSize: 10,
                  beginAtZero: true,

                  suggestedMin: 0,
                  min: 0,
                  max: 100,
                  fontSize: 10,
                },
                // type: "linear",
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: { boxWidth: 10, boxHeight: 10 },
          },
        },
      });

    },
  },
};
</script>

<style></style>
